import React from "react";
import { useState } from "react";
import url from "../../../url ";
import AutoHideAlert from "../../../alrt";
import './PopUpForPay.css';

function getMonthAndYear() {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}/${String(year).slice(2)}`;
}

function PopUpForPay(props) {
    const { idStorePay, lod, setLod, table, id, role } = props;
    const [Spiner1, setSpiner1] = useState(false);
    const [lodInpot1, setlodInpot1] = useState("");
    const [noteInpot, setNoteInpot] = useState("");
    const [isAlrt, setIsAlrt] = useState(false)
    const [message, setmessage] = useState('')
    const [forMonth, setForMonth] = useState(getMonthAndYear());
    const storePay = async() => {
        if (lodInpot1 === "" || Number(lodInpot1) <= 0) {
          // alert("הכנס סכום תקין");
          setmessage("הכנס סכום תקין")
          setIsAlrt(true)
          setTimeout(() => {
            setmessage("")
            setIsAlrt(false)
          }, 5000);
          return;
        }
        setSpiner1(true)
        try{
          let jsonData = {'amount' : lodInpot1}
          if (table === 'club_store_payments') {
            jsonData['store_id'] = idStorePay;
          }
          if (table === 'store_pay_to_keilotkard') {
            jsonData['עבור חודש'] = forMonth.split("/")[1] + '/' + forMonth.split("/")[0] + '/01';
          }
          jsonData['הערות'] = noteInpot;
          const link = `${url}/${role}/${id}/add-raw/add/${table}`;
          const res = await fetch(link, {
            method: "POST",
            credentials: 'include',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(jsonData),
          });
          const data = await res.json();
          setSpiner1(false)
          if (data.status ===  'success') {
            close();
            // alert("הצליח");
            const successMessage = table === 'club_load_history' ? 'לצורך אישור הטעינה יש לשלוח אסמכתא על ביצוע העברה למייל של קהילות קארד' : 'הפעולה בוצעה בהצלחה';
            setmessage(successMessage)
            setIsAlrt(true)
            setTimeout(() => {
              setmessage("");
              setIsAlrt(false);
            }, 5000);
            return;
          }
          else{
            // alert("שגיאה");
            setmessage(" שגיאה  ")
            setIsAlrt(true)
            setTimeout(() => {
              setmessage("")
            setIsAlrt(false)
            }, 5000);
          }
        }
        catch(e){
          // alert("שגיאה");
          setmessage(" שגיאה ")
          setIsAlrt(true)
          setTimeout(() => {
            setmessage("")
          setIsAlrt(false)
          }, 5000);
          console.log(e);
        }
      }

    const close = () => {
        setLod(false);
    };

    const chengeMonth = (num) => {
        const date = new Date(forMonth.split("/")[1], forMonth.split("/")[0] - 1);
        console.log(date);
        if (date === 'Invalid Date') {
            return;
        }
        date.setMonth(date.getMonth() + num);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        setForMonth(`${month}/${String(year).slice(2)}`);
    };
    return (
        <div>
          {isAlrt && <AutoHideAlert message={message}/>}
            {lod && (
              <div className="pop-up-div">  
              <label className="label">איך מטעינים לארנק ?
                <br />
                1. עושים העברה בנקאית של הסכום שרוצים להטעין (לפחות 5000 ש"ח) לבנק של קהילות קארד. - פרטי הבנק: בנק דיסקונט (11) סניף 069 חשבון 201209472
                <br />
                2. מעדכנים את הטעינה במערכת
                <br />
                3. אחרי שהטעינה מאושרת, אפשר להטעין לכרטיסים.</label>   
              <br />
            <label htmlFor="lod" className="label">{table !== 'club_load_history' ? 'סכום לתשלום' : `הקש את הסכום שהטענת לארנק שלך בחשבון הבנק של קהילות קארד`}</label>
              <input
                type="number"
                value={lodInpot1}
                onChange={(e) => setlodInpot1(e.target.value)}
              />
              <br />        
            <label htmlFor="lod">הערות</label>
              <input
                type="text"
                value={noteInpot}
                onChange={(e) => setNoteInpot(e.target.value)}
              />
              <br />
              {table === 'store_pay_to_keilotkard' && (
              <>
              <label htmlFor="lod">עבור חודש</label>
              <input
                type="text"
                value={forMonth}
                onChange={(e) => setForMonth(e.target.value)}
              />
              <div>
              <button onClick={() => chengeMonth(-1)}>הקודם</button>
              <button onClick={() => setForMonth(getMonthAndYear())}>החודש הנוכחי</button>
              <button onClick={() => chengeMonth(1)}>הבא</button>
              </div>
              </>
              )}
               {Spiner1 === true ?(
                <div className="divlod">
                  <div className="loader"></div>
                </div>) :
                <div>  
              <button onClick={()=>storePay()}>תשלום</button>
              <button onClick={() =>{ close() }}>ביטול</button>
              </div>}
            </div>
          )}
        </div>
    );
}

export default PopUpForPay;